body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Dashboard {
  display: flex;
  flex-direction: column;
}

.add-property .Polaris-Card .Polaris-Heading {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
}
.add-property .Polaris-Card__Section .Polaris-Stack__Item {
  padding: 3px 3px 3px 3px;
  line-height: 0.2rem;
  /* padding-bottom: -1rem; */
}

.Polaris-Spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;
}

/* .tab {

} */
/* .Polaris-Tabs .Polaris-Tabs__TabContainer { */
/* justify-content: flex-end; */
/* justify-content: space-evenly; */
/* margin-right: 10px; */
/* } */
/* #New-NFT { */
/* justify-content: flex-end; */
/* margin-left: 260px; */
/* margin-right: 10px; */
/* } */

/* @media screen and (min-width: 900px) {
  .topbar-shopify li:nth-last-child(2) {
    position: absolute;
    right: 0;
    margin-right: 15px;
  }
} */

a {
  text-decoration: none;
  color: black;
}

.image-text {
  font-weight: 240;
}

.box {
  inline-size: 150px;
  overflow: scroll;
  /* text-overflow: ellipsis; */
}

.agreement-text {
  color: rgba(109, 113, 117, 1);
  padding-left: 3rem;
}

.agreement-link {
  font-weight: 600;
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-16 {
  margin-bottom: 16px;
}

.center {
  text-align: center;
}

.description {
  margin-bottom: 2rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Dashboard {
  display: flex;
  flex-direction: column;
}

.Polaris-Page .Polaris-Header-Title {
  font-size: 1.6rem;
}

#publish-modal {
  .Polaris-Banner {
    margin-top: 1rem;
  }
}

#import-step1, #import-step2, #import-step3, #import-queue {
  color: rgba(0,0,0,0.8);
  hr { border-top: rgba(0,0,0,.2); }
  .Polaris-Page > div > .Polaris-Card {
    margin-bottom: 2rem;
    padding: 0.6rem; 
  }
  .Polaris-Heading { font-size: 1.2rem; }
  .Polaris-ResourceItem__Container { align-items: center; }
  .Polaris-ResourceItem__Media { display: flex;}
  .Polaris-ResourceItem__Content {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .Polaris-TextContainer { margin: 2.6rem 0rem; }
  .Polaris-InlineError {
    align-items: center;
  }
  .Polaris-Icon--colorBase svg {
    transition: fill 0.4s;
    fill: rgba(0, 0, 0, 0.6);
    &:hover{
      fill: rgba(0, 0, 0, 0.4)
    }
    &:active{
      fill: rgba(0, 0, 0, 0.8)
    }
  }
}
#import-queue {
  .align-center {
    text-align: center;
  } 
  .Polaris-Page > div > .Polaris-Stack > .Polaris-Stack__Item:first-child { 
    flex: 1 1 400px;
  }
  #table-menu{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 0.8rem;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    .Polaris-Filters {
      flex: 1 0 auto;
      .Polaris-Filters-ConnectedFilterControl {
        justify-content: flex-end;
      }
    }
    button#refresh {
      padding: 0.1rem 0.8rem;
      flex: 0;
      cursor: pointer;
      display: flex;
      gap: 0.4rem;
      justify-content: flex-end;
      align-items: center;
      border: none;
      border-radius: 0.2rem;
      background: none;
      transition: background-color 0.3s ease-in-out;
      &:hover {
        background-color: var(--p-background-hovered);
        opacity: 0.9;
      }
      &:active {
        background-color: var(--p-background-pressed);
        opacity: 0.7;
      }
      span {
        flex: 0 0 auto;
      }
    }
  }
  .Polaris-IndexTable__TableCell {
    padding: 0.5rem 0.4rem;
    &:nth-child(3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 10rem;
    }
    .Polaris-Stack {
      margin: 0;
    }
    .Polaris-Stack__Item {
      display: inherit;
      margin: 0rem 0.6rem;
    }
    &:nth-child(5) {
      .Polaris-Badge{
        line-height: 20px;
        vertical-align: middle;
      }
      button#retry{
        vertical-align: middle;
        display: inline-flex;
        cursor: pointer;
        border: none;
        border-radius: 0.2rem;
        background: none;
        transition: background-color 0.3s ease-in-out;
        &:hover {
          background-color: var(--p-background-hovered);
          opacity: 0.9;
        }
        &:active {
          background-color: var(--p-background-pressed);
          opacity: 0.7;
        }
        span {
          vertical-align: middle;
        }
      }
    }
  }
  nav > .Polaris-ButtonGroup--segmented {
    justify-content: center;
  }
}
#import-step1 {
  #consignors {
    .isSelected + .Polaris-Card{
      background-color: #e4efff;
    }
    .Polaris-ChoiceList__Choices li:not(:last-child) {
      margin-bottom: 0.4rem;
    }
    .Polaris-Choice {
      display: flex;
    } 
    .Polaris-Choice__Control { display: none; }
    .Polaris-Choice__Label { flex: 1 }
  }
  #terms {
    margin-bottom: 1rem;
  }
  #actions .Polaris-Stack {
    margin-bottom: 1rem;
  }
  
}
#import-step2 {
  code {
    background: rgba(0,0,0,0.10);
    padding: 0.1rem 0.3rem;
    font-size: 0.8rem;
    color: var(--p-action-critical);
    border-radius: 2px;
    font-style: italic;
  }
  #copy-address{
    :not(.Polaris-Stack__Item:first-child, .Polaris-Stack__Item:last-child) {
      flex: 1 0 auto;
    }
    button {
      cursor: pointer;
      background: transparent;
      border: none;
    }
  }
  #contact-us {
    .Polaris-Icon {
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.4rem;
    }
  }
  .Polaris-Spinner {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    margin: 0 1rem;
    vertical-align: middle;
  }
}
#import-step3 {
  :not(.Polaris-Page > div > .Polaris-Card) .Polaris-Card:not(:only-of-type) {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.1);
  }
  #consignor-info {
    margin-bottom: 1.2rem;
    .Polaris-Card__Section {
      padding: 0;
    }
    .Polaris-Stack {
      margin: 0;
      gap: 1rem;
      height: 60px;
      padding: 0 1rem;
    }
    .Polaris-Stack__Item {
      margin: 0;
      &:first-child {
        padding-top: 0.2rem;
      }
      &:last-child {
        flex: 1;
        text-align: right;
        min-width: 0;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .Polaris-Label__Text {
    font-size: 1.2rem;
  }
}

.Polaris-Spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;
}

.Polaris-Page-Header {
  margin-bottom: 10px;
}

.Polaris-Modal-Dialog .Polaris-Modal-Dialog__Modal {
  box-shadow: 5px 15px 30px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 5px 15px 30px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 15px 30px -4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.Polaris-Popover__Wrapper {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #eee;
}

.Polaris-Card {
  border-radius: 10px;
}

.Polaris-TextField .Polaris-TextField__Backdrop, .Polaris-Button, .Polaris-Select__Backdrop {
  border-radius: 5px;
}

// tmp solution
.Polaris-EmptyState--imageContained img {
  position: initial;
  width: 100%;
  max-width: 400px;
}

.Polaris-Card__Section .Polaris-DisplayText--sizeSmall {
  font-size: 1rem;
}

.pre-line {
  white-space: pre-line;
}

.badge-property-overrides {
  &[p-color-scheme="light"] {
    --p-background: rgba(255, 244, 244, 1);
    --p-background-hovered: rgba(255, 238, 238, 1);
    --p-background-pressed: rgba(255, 233, 233, 1);
    --p-background-selected: rgba(255, 233, 233, 1);
    --p-surface-neutral: rgba(255, 221, 221, 1);
    --p-surface-neutral-hovered: rgba(255, 210, 210, 1);
    --p-surface-neutral-pressed: rgba(255, 187, 187, 1);
    --p-surface-neutral-disabled: rgba(255, 238, 238, 1);
    --p-surface-neutral-subdued: rgba(255, 244, 244, 1);
    --p-surface-subdued: rgba(255, 249, 249, 1);
    --p-surface-disabled: rgba(255, 249, 249, 1);
    --p-surface-hovered: rgba(255, 244, 244, 1);
    --p-surface-pressed: rgba(255, 238, 238, 1);
    --p-surface-depressed: rgba(255, 233, 233, 1);
    --p-surface-search-field: rgba(255, 238, 238, 1);
  }

  &[p-color-scheme="dark"] {
    --p-background: rgba(35, 0, 0, 1);
    --p-background-hovered: rgba(35, 0, 0, 1);
    --p-background-pressed: rgba(35, 0, 0, 1);
    --p-background-selected: rgba(35, 0, 0, 1);
    --p-surface: rgba(75, 0, 0, 1);
    --p-surface-neutral: rgba(109, 0, 0, 1);
    --p-surface-neutral-hovered: rgba(109, 0, 0, 1);
    --p-surface-neutral-pressed: rgba(109, 0, 0, 1);
    --p-surface-neutral-disabled: rgba(109, 0, 0, 1);
    --p-surface-neutral-subdued: rgba(147, 0, 0, 1);
    --p-surface-subdued: rgba(65, 0, 0, 1);
    --p-surface-disabled: rgba(65, 0, 0, 1);
    --p-surface-hovered: rgba(105, 0, 0, 1);
    --p-surface-pressed: rgba(134, 0, 0, 1);
    --p-surface-depressed: rgba(170, 0, 0, 1);
    --p-surface-search-field: rgba(105, 0, 0, 1);
  }
}
